import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/css/default.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
      <section className='footer_area gradiant_bg pt_30 pb_30'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12 menu_right'>
              <div className='menu_footer'>
                <nav>
                  <ul>
                    <li>
                      <Link to='/signin' className='start_now_button'>Login</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>

  )
}

export default Footer
