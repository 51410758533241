import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/css/default.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import Job from '../../assets/img/hero/jobs.png'
import { StartNow } from './StartNow'
import { Contents } from './Contents'

const BenefitsOfUsingIncantation = () => {
  const content = [
    {
      title: 'The life you deserve',
      content: 'The quality of your life will vastly improve once you set, view and verbalize your intentions. This process will allow you to master your own emotions and live your most fulfilled life possible. When you set incantations they are personalized to you.'
    },
    {
      title: 'Live life on your own terms',
      content: 'Think of areas of your life in which you could most benefit from practicing incantations. This can be confidence, relationships, money, or fitness for example, among many other areas.'
    },
    {
      title: 'Adapt life changing habits',
      content: 'Habits take effort to create, and you can focus on getting it done. With new habits, you control what you do, and by extension, your circumstances. Incantation will help you stay on the right track.'
    }
  ]

  return (
    <>
      {/*  white spaces */}
      <section className='bg_whit pt_20 pb_20' />
      {/*   white spaces end */}

      {/*    start countdown snd area */}
      <section className='countdown_area bg_gray_left pt_40 pb_30'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-6 col-lg-6 col-md-12 text-right'>
              <div className='side_countdown_img'>
                <img src={Job} alt='Benefits of using incantation' />
                <div className='single_area_btn pt_20 pb_20 big_screen'>
                  <StartNow />
                </div>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <Contents contents={content} />
              {/*    only mobile and tablet screen */}
              <div className='single_area_btn pt_20 pb_20 mobile_screen mobile_5'>
                <StartNow />
              </div>
              {/*  only mobile and tablet screen end */}
            </div>
          </div>
        </div>
      </section>
      {/* start countdown area end */}
    </>

  )
}

export default BenefitsOfUsingIncantation
