import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { useSelector } from 'react-redux'
import { ThemeSetting } from './settings/ThemeSetting'
import { RefreshDelaySetting } from './settings/RefreshDelaySetting'
import { ContactInformation } from './settings/ContactInformation'
import './../assets/css/style.css'
import './../assets/css/responsive.css'
import './../assets/css/default.css'
import HeaderArea from './HeaderArea/HeaderArea'


const Settings = () => {
  const darkTheme = useSelector((state) => state.darkTheme)
  const themeColor = darkTheme ? 'dark_body' : 'color_white'
  const boxColor = darkTheme ? 'black_modal color_white' : ''
  const sideShape = darkTheme ? 'z_index' : ''
  return (
    <>
      <HeaderArea />
      <div className={themeColor} >
        <section className={['multiple_add_area', 'side_vector_bg', 'pt_60', 'side_vector_pb', sideShape].join(' ')}>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='row'>
                  <div className='col-xl-7 col-lg-8 offset-lg-2 col-md-10 offset-md-1 offset-xl-2 col-12'>
                    <div className={['box_update_page', boxColor].join(' ')}>
                      <RefreshDelaySetting />
                      <ThemeSetting />
                      <ContactInformation />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Settings
