import { Redirect } from 'react-router'
import { useDispatch } from 'react-redux'
import React, { useEffect } from 'react'
import { logout } from '../store/user'
import * as firebase from 'firebase/app'

export const SignOut = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    try {
      firebase.auth().signOut().then(() => dispatch(logout()))
    } catch (error) {
      console.error('error signing out: ', error)
    }
  })
  return <Redirect to='/' />
}
