import React, { useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import Settings from './pages/Settings'
import { SignIn } from './pages/SignIn'
import { SignOut } from './pages/SignOut'
import ManageIncantations from './pages/ManageIncantations'
import Incantation from './pages/Incantation'
import Home from './pages/Home'
import { ProtectedRoute } from './ProtectedRoute'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { loadIncantations } from './store/incantation'
import { useDispatch } from 'react-redux'
import { loadDarkTheme } from './store/theme'
import { updateRefreshDelayInMinutes } from './store/refreshDelay'
import { login, logout } from './store/user'

function App () {
  const dispatch = useDispatch()

  useEffect(() => {
    let unsubscribeIncantations = null
    let unsubscribeTheme = null
    let unsubscribeDuration = null
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        dispatch(login(user))
        unsubscribeIncantations = firebase.firestore().collection('incantation')
          .where('userId', '==', user.uid)
          .orderBy('timestamp', 'desc')
          .onSnapshot((snapshopt) => {
            dispatch(loadIncantations(
              snapshopt.docs.map((document) => ({ id: document.id, text: document.data().text }))
            ))
          }, (error) => { console.error('incantation listening error', error) })
        unsubscribeTheme = firebase.firestore().collection('settings').doc('darkTheme' + user.uid)
          .onSnapshot((doc) => {
            dispatch(loadDarkTheme(
              doc.data() ? doc.data().theme : false
            ))
          }, (error) => { console.error('theme listening error', error) })
        unsubscribeDuration = firebase.firestore().collection('settings').doc('refreshDelay' + user.uid)
          .onSnapshot((doc) => {
            dispatch(updateRefreshDelayInMinutes(
              doc.data() ? doc.data().refreshDelay : 30
            ))
          }, (error) => { console.error('refreshDelay listening error', error) })
      } else {
        if (unsubscribeIncantations) unsubscribeIncantations()
        if (unsubscribeTheme) unsubscribeTheme()
        if (unsubscribeDuration) unsubscribeDuration()
        dispatch(logout())
      }
    })
  })

  return (
    <Router>
      <Switch>
        <Route exact path='/signin' component={SignIn} />
        <Route exact path='/signout' component={SignOut} />
        <ProtectedRoute exact path='/settings' component={Settings} />
        <ProtectedRoute exact path='/incantation' component={ManageIncantations} />
        <ProtectedRoute exact path='/' component={Incantation} alternativeUnprotectedComponent={Home} />
        <Redirect to='/' />
      </Switch>
    </Router>
  )
}

export default App
