import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import './../../assets/css/default.css'
import './../../assets/css/responsive.css'
import './../../assets/css/style.css'
import { Link } from 'react-router-dom'

const MenuList = () => {
  return (
    <ul>
      <li>
        <Link to='/home'>Home</Link>
      </li>
      <li>
        <Link to='/incantation'>Add</Link>
      </li>
      <li>
        <Link to='/settings'>Settings</Link>
      </li>
      <li>
        <Link to='/signout'>Logout</Link>
      </li>
    </ul>

  )
}

export default MenuList
