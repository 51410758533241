import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/css/default.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import QuoteHero from '../../assets/img/hero/qote-hero.png'
import { StartNow } from './StartNow'

const Hero = () => {
  return (
    <>
      <section className='hero_area bg_shape pt_30 pb_120'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='logo_area'>
                <h1>Incantation</h1>
                <p>Under development</p>
              </div>
            </div>
          </div>
          <div className='row pt_150'>
            <div className='col-xl-4 col-lg-5 col-md-5'>
              <div className='hero_content'>
                <h2>Live Life on Your Terms</h2>
                <p>Form new habits and take them with you everywhere</p>
                <StartNow />
              </div>
            </div>
            <div className='col-xl-8 col-lg-7 col-md-7'>
              <div className='hero_quote_image'>
                <img src={QuoteHero} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='menubar_area bg_blue pt_20 pb_20'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-4 col-4 single_items'>
              <div className='navigate_link'>
                <h4>The life you deserve</h4>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-4 col-4 single_items'>
              <div className='navigate_link'>
                <h4>Live life on your own terms</h4>
              </div>
            </div>
            <div className='col-xl-4 col-lg-4 col-md-4 col-4 single_items'>
              <div className='navigate_link'>
                <h4>Adapt life changing habits</h4>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Hero
