import React from 'react'
import Footer from './home/Footer'
import Hero from './home/Hero'
import CostOfNotUsingIncantation from './home/CostOfNotUsingIncantation'
import BenefitsOfUsingIncantation from './home/BenefitsOfUsingIncantation'
import ThreeEasySteps from './home/ThreeEasySteps'
import WhyUs from './home/WhyUs'


const Home = () => {
  return (
    <>
      <Hero />
      <CostOfNotUsingIncantation />
      <BenefitsOfUsingIncantation />
      <ThreeEasySteps />
      <WhyUs />
      <Footer />
   </>
  )
}

export default Home
