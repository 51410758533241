import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const LOAD_INCANTATIONS = 'LOAD_INCANTATIONS'

export function handleAddIncantations (incantations) {
  return async (dispatch) => {
    try {
      await Promise.all(
        incantations.map(incantation => {
          return firebase.firestore().collection('incantation').add({
            text: incantation,
            timestamp: Date.now(),
            userId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''
          })
        }))
    } catch (err) {
      console.error('error creating incantations:', err)
    }
  }
}

export function loadIncantations (incantations) {
  return {
    type: LOAD_INCANTATIONS,
    incantations
  }
}

export function handleDeleteIncantation (id) {
  return (dispatch) => {
    firebase.firestore().collection('incantation').doc(id).delete().then(function () {
    }).catch(function (error) {
      console.error('Error removing document: ', error)
    })
  }
}

export function incantationsReducer (state = [], action) {
  switch (action.type) {
    case LOAD_INCANTATIONS:
      return action.incantations ? action.incantations : []
    default:
      return state
  }
}
