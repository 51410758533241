import { Redirect } from 'react-router'
import { useSelector } from 'react-redux'
import React from 'react'
import LogoArea from './signin/LogoArea'
import BannerLogin from './signin/BannerLogin'
import LoginForm from './signin/LoginForm'

export const SignIn = () => {
  const user = useSelector(state => state.user)

  if (user) {
    return <Redirect to='/' from='/signin' />
  }
  return (
    <>
      <LogoArea />
      <section className='login_area'>
        <div className='container'>
          <div className='row'>
            <BannerLogin />
            <LoginForm />
          </div>
        </div>
      </section>
 </>

  )
}
