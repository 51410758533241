import React from 'react'

export const ContactInformation = () => {
  return (
    <>
      <div className='col-md-auto'>
        <label>
            for feedback, feature requests, or account deletion requests, please send us an email at:
          <a href='mailto:m@far.ag'> m@far.ag</a>
        </label>
      </div>
    </>
  )
}
