import { handleRefreshDelayInMinutes } from '../../store/refreshDelay'
import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { useDispatch, useSelector } from 'react-redux'
import { IntegerInput } from './IntegerInput'
import './../../assets/css/default.css'
import './../../assets/css/responsive.css'
import './../../assets/css/style.css'

export const RefreshDelaySetting = () => {
  const dispatch = useDispatch()
  const refreshDelayInMinutes = useSelector(state => state.refreshDelayInMinutes)
  const [delay, setDelay] = useState(refreshDelayInMinutes)

  function handleChange (value) {
    setDelay(value)
  }

  const handleUpdateRefreshDelayInMinutes = () => {
    dispatch(handleRefreshDelayInMinutes(delay))
  }

  return (
    <div className='row border_bottom_cs'>
      <div className='col-xl-7 col-lg-7 col-md-7 '>
        <label htmlFor='refresh' className='refresh_minutes'><span>Refresh Page every</span>
          <IntegerInput
            initialValue={refreshDelayInMinutes} onChange={handleChange}
          />
          <span>minutes</span>
        </label>
      </div>
      <div className='col-xl-5 col-lg-5 col-md-5 text-right'>
        <div className='minit_area'>
          <label htmlFor='minute'>
            <button className='btnc' onClick={handleUpdateRefreshDelayInMinutes}>
           Update duration
            </button>
          </label>
        </div>
      </div>
    </div>
  )
}
