import { applyMiddleware, combineReducers, createStore } from 'redux'
import thunk from 'redux-thunk'
import { incantationsReducer } from './incantation'
import { themeReducer } from './theme'
import { refreshDelayReducer } from './refreshDelay'
import { userReducer } from './user'

export const store = createStore(combineReducers({
  incantations: incantationsReducer,
  darkTheme: themeReducer,
  refreshDelayInMinutes: refreshDelayReducer,
  user: userReducer
}), applyMiddleware(thunk))
