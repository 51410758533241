import React from 'react'
import Signup from '../../assets/img/hero/sign-up.png'
const BannerLogin = () => {
  return (
    <>
      <div className='col-xl-6 col-lg-6 col-md-6 bg_loging'>
        <div className='login_middle'>
          <div className='banner_login wow fadeInLeft'>
            <img src={Signup} alt='' />
          </div>
        </div>
      </div>
    </>
  )
}
export default BannerLogin
