import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const UPDATE_REFRESH_DELAY = 'UPDATE_REFRESH_DELAY'

export function updateRefreshDelayInMinutes (duration) {
  return {
    type: UPDATE_REFRESH_DELAY,
    duration: duration
  }
}

export function handleRefreshDelayInMinutes (duration) {
  return (dispatch) => {
    firebase.firestore()
      .collection('settings')
      .doc('refreshDelay' + (firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''))
      .set({
        refreshDelay: duration,
        timestamp: Date.now(),
        userId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''
      }).catch((e) => {
        console.error('Error saving refresh delay', e)
      })
  }
}

export function handleLoadRefreshDelayInMinutes () {
  return (dispatch) => {
    // Auth.currentAuthenticatedUser().then(user => {
    //   const duration = user.attributes['custom:delayTimeInMinutes']
    //   dispatch(updateRefreshDelayInMinutes(duration))
    // })
  }
}

export function refreshDelayReducer (state = 30, action) {
  switch (action.type) {
    case UPDATE_REFRESH_DELAY:
      return action.duration || state
    default:
      return state
  }
}
