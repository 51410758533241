import React from 'react'

import { Redirect, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'

export const ProtectedRoute = ({ component: Component, alternativeUnprotectedComponent: AlternativeComponent, ...rest }) => {
  const user = useSelector(state => state.user)

  if (!user) {
    if (AlternativeComponent) {
      return (
        <Route
          {...rest}
          render={props => <AlternativeComponent {...rest} {...props} />}
        />
      )
    } else {
      return (
        <Redirect to={{ pathname: '/', state: { from: rest.location } }} />)
    }
  }
  return (
    <Route {...rest} render={props => <Component {...rest} {...props} />} />
  )
}
