const USER_LOGIN = 'USER_LOGIN'
const USER_LOGOUT = 'USER_LOGOUT'

export function login (user) {
  return {
    type: USER_LOGIN,
    user
  }
}

export function logout () {
  return {
    type: USER_LOGOUT
  }
}

export function userReducer (state = null, action) {
  switch (action.type) {
    case USER_LOGIN:
      return action.user
    case USER_LOGOUT:
      return null
    default:
      return state
  }
}
