import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons'
import { useDispatch, useSelector } from 'react-redux'
import { handleAddIncantations, handleDeleteIncantation } from '../store/incantation'
import HeaderArea from './HeaderArea/HeaderArea'
import './../assets/css/style.css'
import './../assets/css/responsive.css'
import './../assets/css/default.css'
import 'font-awesome/css/font-awesome.min.css'

const ManageIncantations = () => {
  const [newIncantations, setNewIncantations] = useState('')
  const [colorContentArea, setcolorContentArea] = useState(' dark_body')
  const [editSavedIncantation, seteditSavedIncantation] = useState('d-none')
  const dispatch = useDispatch()
  const incantations = useSelector((state) => state.incantations)
  const darkTheme = useSelector((state) => state.darkTheme)
  const themeColor = darkTheme ? colorContentArea : 'color_white'
  const sideShape = darkTheme ? 'z_index' : ''
  const itemTheme = darkTheme ? 'black_modal' : ''
  const [deleteItem, setdeleteItem] = useState('d-none')
  const handleIncantationChange = (event) => {
    setNewIncantations(event.target.value)
  }

  const saveIncantation = () => {
    dispatch(
      handleAddIncantations(
        newIncantations.split('\n').filter((line) => line.length !== 0)
      )
    )
    setNewIncantations('')
  }

  const deleteIncantation = (index) => {
    dispatch(handleDeleteIncantation(index))
  }

  const cancelEditIncantation = () => {
    seteditSavedIncantation('d-none')
  }

  const editItemModal = () => {
    setcolorContentArea(' dark_body modal-open')
    seteditSavedIncantation('d-block')
  }
  const deleteItemModal = () => {
    setdeleteItem('d-block')
  }

  const cancelDeleteItem = () => {
    setdeleteItem('d-none')
  }

  const deleteSelectedButton = () => {
    setdeleteItem('d-none')
    /* MUST ADD DELETE SELECTED DATA */
    /* if ($('#product').is(':checked')) {
      console.log('test')
    } */
  }

  return (
    <>
      <HeaderArea />
      <div className={themeColor}>
        {/* manage content area */}
        <section className={['multiple_add_area', 'side_vector_bg', 'pt_50', 'pb_100', sideShape].join(' ')}>
          <div className='container'>
            <div className='row'>
              <div className='col-xl-12'>
                <div className='row'>
                  <div className='col-md-10 offset-md-1'>
                    <div className='multiple_adding_line color_white'>
                      <p>You can add multiple incantations by putting each in a new line</p>
                      <div className='multiple_text_area'>
                        <form action='#'>
                          <textarea
                            name='' id='' cols='30' rows='10' placeholder='Write your Incantation'
                            value={newIncantations}
                            onChange={handleIncantationChange}
                          />
                        </form>
                        <div className='row'>
                          <div className='col-md-6 col-6 text-left'/>
                          <div className='col-md-6 col-6 text-right'>
                            <button
                              className='btnc' style={{ textDecoration: 'none' }}
                              onClick={saveIncantation}
                              disabled={!newIncantations}
                            >ADD
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={darkTheme ? 'adding_list_items' : 'adding_list_items white_theme'}
                      >
                        <ul>
                          {incantations.map((incantation) => (
                            <li key={incantation.id}>
                              <label
                                id='test'
                                style={darkTheme ? { color: '#fff' } : { color: '#004A64' }}
                              >{incantation.text}
                              </label>
                              <span>
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  onClick={() => {
                                    deleteIncantation(incantation.id)
                                  }}
                                  className=' fontawesomeicon color_edit_2 '
                                />
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* manage content area end */}

        {/* Edit Item Modal */}
        <div className={['modal', 'select-item-modal', ' save_modal', itemTheme, editSavedIncantation].join(' ')}>
          <div className='modal-diglog center-mid'>
            <div className='modal-content animated col-xl-6 items_mt'>
              <div className='modal-body'>
                {/* Body */}
                <div className='row button_modal black_modal_text'>
                  <div className='col-12 text-center'>
                    <div className='whit_border_notice'>
                      <textarea name='' id='' cols='30' rows='10' />
                    </div>
                  </div>
                  <div className='col-md-6 col-6 text-right'>
                    <button className='btnc btn_gray' data-dismiss='modal' onClick={cancelEditIncantation}>
                      Cancel
                    </button>
                  </div>
                  <div className='col-md-6 col-6 text-left'>
                    <button className='btnc'>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* delate item Modal */}
        <div className={['modal', 'select-item-modal', itemTheme, deleteItem].join(' ')} id='delate_item'>
          <div className='modal-diglog center-mid'>
            <div className='modal-content animated col-xl-6 items_mt'>
              <div className='modal-body'>
                {/* Body */}
                <div
                  className={darkTheme ? 'row button_modal white_modal_text' : 'row button_modal'}
                >
                  <div className='col-12 text-center'>
                    <h4 style={darkTheme ? { color: '#fff' } : { color: '#004A64' }}>Are you sure, this operation is not reversible</h4>
                  </div>
                  <div className='col-md-6 col-6 text-right'>
                    <a href='#' className='btnc btn_gray' onClick={cancelDeleteItem}>Cancel</a>
                  </div>
                  <div className='col-md-6 col-6 text-left'>
                    <a href='#' className='btnc' onClick={deleteSelectedButton}> Yes</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* delate item Modal end */}

      </div>
    </>

  )
}

export default ManageIncantations
