import React from 'react'

const LogoArea = () => {
  return (
    <section className='hero_area pt_30 pb_20'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='logo_area'>
              <h1>Incantation</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default LogoArea
