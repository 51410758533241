import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/css/default.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import Pic1 from '../../assets/img/hero/pic-1.png'
import Pic2 from '../../assets/img/hero/pic-2.png'
import Pic3 from '../../assets/img/hero/pic-3.png'
import { StartNow } from './StartNow'

const ThreeEasySteps = () => {
  return (

    <section className='single_steps_area pt_50 pb_50'>
      <div className='container'>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='header_text text-center'>
              <h2>Three Easy Steps</h2>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-4 col-lg-4 col-md-12 mb_30'>
            <div className='single_steps_box'>
              <div className='single_steps_icon'>
                <div className='row'>
                  <div className='col-md-6 col-6'>
                    <div className='steps_icon_left'>
                      <img src={Pic1} alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 col-6'>
                    <div className='steps_icon_right'>
                      <span className='icons_number'>01</span>
                    </div>
                  </div>
                </div>
              </div>
              <h3>Click Start Now</h3>
              <p>Few clicks to setup and keep your incantations synced across all your devices</p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-4 col-md-12 mb_30'>
            <div className='single_steps_box'>
              <div className='single_steps_icon'>
                <div className='row'>
                  <div className='col-md-6 col-6'>
                    <div className='steps_icon_left'>
                      <img src={Pic2} alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 col-6'>
                    <div className='steps_icon_right'>
                      <span className='icons_number'>02</span>
                    </div>
                  </div>
                </div>
              </div>
              <h3>Enter your incantations</h3>
              <p>Enter one incantation or a hundred in one easy step</p>
            </div>
          </div>
          <div className='col-xl-4 col-lg-4 col-md-12 mb_30'>
            <div className='single_steps_box'>
              <div className='single_steps_icon'>
                <div className='row'>
                  <div className='col-md-6 col-6'>
                    <div className='steps_icon_left'>
                      <img src={Pic3} alt='' />
                    </div>
                  </div>
                  <div className='col-md-6 col-6'>
                    <div className='steps_icon_right'>
                      <span className='icons_number'>03</span>
                    </div>
                  </div>
                </div>
              </div>
              <h3>Keep it open anywhere
                  or everywhere
              </h3>
              <p>Yes, you can open it at home, at work, on your mobile and even on most smart TVs</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div className='single_area_btn'>
              <StartNow />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ThreeEasySteps
