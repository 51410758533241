import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

const LOAD_DARK_THEME = 'LOAD_DARK_THEME'

export function handleToggleDarkTheme (darkTheme) {
  return (dispatch) => {
    firebase.firestore()
      .collection('settings')
      .doc('darkTheme' + (firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''))
      .set({
        theme: !darkTheme,
        timestamp: Date.now(),
        userId: firebase.auth().currentUser ? firebase.auth().currentUser.uid : ''
      }).catch(() => {
        console.error('Error saving theme')
      })
  }
}

export function loadDarkTheme (darkTheme) {
  return {
    type: LOAD_DARK_THEME,
    darkTheme
  }
}

export function themeReducer (state = false, action) {
  switch (action.type) {
    case LOAD_DARK_THEME:
      return action.darkTheme
    default:
      return state
  }
}
