import React, { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { useDispatch, useSelector } from 'react-redux'
import useWindowDimensions from './useWindowDimentions'
import HeaderArea from './HeaderArea/HeaderArea'
import './../assets/css/style.css'
import './../assets/css/responsive.css'
import './../assets/css/default.css'

const Incantation = () => {
  const { height, width } = useWindowDimensions()
  const [time, setTime] = useState(Date.now())

  const darkTheme = useSelector((state) => state.darkTheme)
  const incantations = useSelector((state) => state.incantations)
  const refreshDelayInMinutes = useSelector(
    (state) => state.refreshDelayInMinutes
  )
  const incantation =
    incantations[Math.floor(Math.random() * incantations.length)]
  const dispatch = useDispatch()

  useEffect(() => {
    const interval = setInterval(
      () => setTime(Date.now()),
      refreshDelayInMinutes * 60 * 1000
    )
    return () => {
      clearInterval(interval)
    }
  }, [time, refreshDelayInMinutes, dispatch, incantations])

  const fontSize = () => {
    const fontSizeToHeightRatio = 15
    return height / fontSizeToHeightRatio
  }

  const marginVertical = height / 2 - 3 * fontSize()
  const marginHorizontal = width / 10

  return (
    <>
      <div className={darkTheme ? 'dark_body' : 'color_white'}>
        <HeaderArea />
        <main style={{ width: '100%' }}>
          <div
            style={{
              fontSize: fontSize(),
              fontWeight: 'bold',
              marginTop: marginVertical,
              marginRight: marginHorizontal,
              marginLeft: marginHorizontal,
              textAlign: 'center'
            }}
          >
            {incantation ? incantation.text : 'Welcome to incantation 🤗. You have no incantations yet, go to Add/Delete page to add some incantations'}
          </div>
        </main>
      </div>
    </>
  )
}

export default Incantation
