import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { Link } from 'react-router-dom'
import './../../assets/css/default.css'
import './../../assets/css/responsive.css'
import './../../assets/css/style.css'
import { useSelector } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import MenuList from './MenuList'

const HeaderArea = () => {
  const [active, setActive] = useState('false')
  function changeClass () {
    setActive('true')
  }
  function removeList () {
    setActive('false')
  }

  const darkTheme = useSelector((state) => state.darkTheme)
  const themeColor = darkTheme ? 'header_dark_light' : ''

  return (
    <>
      <div className={active === 'true' ? 'offcanvas_menu_overly active' : 'offcanvas_menu_overly'} />
      <section className={active === 'true' ? 'offcanvas_menu light_background active' : 'offcanvas_menu light_background'}>
        <span className='menu_close'>
          <FontAwesomeIcon icon={faTimes} className='fatimes' onClick={removeList} />
        </span>
        <div className='menu_item_wrap'>
          <div className='menu_item_inner'>
            <MenuList />
          </div>
        </div>
      </section>
      <header id='header_sticky' className={['header_transparent', 'header_gradient', themeColor].join(' ')}>
        <div className='header_area'>
          <div className='container'>
            <div className='row d-flex align-items-center only_responsive_pd'>
              <div className='col-xl-4 col-lg-4 col-6'>
                <div className='logo_area'>
                  <Link to='/home' className='link'>Incantation</Link>
                </div>
              </div>
              {/* collapse button */}
              <div className='col-xl-8 col-lg-8 col-6 only_mobile_right'>
                <span className='menu_triggers' onClick={changeClass}>
                  <span />
                  <span />
                  <span />
                </span>
                <div className='main_menu text-right'>
                  <nav id='mobile-menu'>
                    <MenuList />
                  </nav>
                </div>
                <div className='mobile-menu' />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>

  )
}
export default HeaderArea
