import React from 'react'

const Content = ({ title, content }) => {
  return (
    <div className='single_box_content'>
      <h4>{title}</h4>
      <p>{content}</p>
    </div>
  )
}
export const Contents = ({ contents }) => {
  return <div className='countdown_content'> {contents.map(
    (item) => (<Content key={item.title} title={item.title} content={item.content} />))}
  </div>
}
