import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/css/default.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import TimeDown from '../../assets/img/hero/time-down.png'
import { StartNow } from './StartNow'
import { Contents } from './Contents'

const CostOfNotUsingIncantation = () => {
  const content = [
    {
      title: 'Avoid progress loss ',
      content: 'It’s easy to lose new healthy habits and fall into old habits. Avoid taking two steps forward and one step back.'
    },
    {
      title: 'Don’t keep your new-self waiting',
      content: 'You don’t want to look back with sorrow on how long it took you to get to your best self. That shame will only make your path forward harder'
    },
    {
      title: 'Don’t let others do it to you',
      content: 'You don’t need to listen to advertisers or cultures telling you the things you need to be happy or live by anyone’s expectations'
    }
  ]

  return (
    <>
      {/*     white spaces */}
      <section className='bg_whit pt_20 pb_20' />
      {/* white spaces end */}
      <section className='countdown_area bg_gray pt_40 pb_30'>
        <div className='container'>
          <div className='row'>
            {/*  only mobile and tablet device */}
            <div className='col-xl-6 col-lg-6 col-md-12 text-right mobile_screen'>
              <div className='side_countdown_img'>
                <img src={TimeDown} alt='' />
              </div>
            </div>
            {/* only mobile and tablet device end */}
            <div className='col-xl-6 col-lg-6 col-md-12'>
              <Contents contents={content} />
              <div className='single_area_btn'>
                <StartNow />
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-12 text-right big_screen'>
              <div className='side_countdown_img'>
                <img src={TimeDown} alt='' />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CostOfNotUsingIncantation
