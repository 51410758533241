import React from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../assets/css/default.css'
import '../../assets/css/style.css'
import '../../assets/css/responsive.css'
import { StartNow } from './StartNow'

const WhyUs = () => {
  return (
    <>
      {/*   change your life area */}
      <section className='change_your_life_area pt_20 pb_30'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='change_life_content bg_gray pt_30 pb_30'>
                <div className='row'>
                  <div className='col-md-10 offset-md-1 col-12'>
                    <h2>Why use Incantation</h2>
                    <p>Tony Robbins coined the term “incantations” as a form of loudly verbalizing empowering phrases with absolute certainty.
                      Every action we take is being driven by emotions. Without learning to manage our emotions, we run the risk of our emotions
                      overwhelming and dictating our lives for us.
                    </p>
                    <p>As one of the students of Tony Robbins incantations have changed my life. I have been using posted notes and printed papers
                      to keep my incantations in sight and it really works. But, seriously! sticking printed papers on the wall and we have all these
                      screens around us? And here comes incantation.app. An automated way to synchronize your incantations across all your devices,
                      whether that is your smart phone, your computer or even your smart TV. Incantation will work on about any web browser.
                    </p>
                    <div className='single_area_btn'>
                      <StartNow />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default WhyUs
