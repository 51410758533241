import React from 'react'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import * as firebase from 'firebase/app'
import 'firebase/auth'

const uiConfig = {
  signInSuccessUrl: '/',
  signInOptions: [
    {
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
      signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD,
      requireDisplayName: false
    },
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: function () {
      // Handle sign-in.
      // Return false to avoid redirect.
      return false
    }
  },
  tosUrl: 'https://google.com',
  privacyPolicyUrl: 'https://google.com'
}

const LoginForm = () => {
  return (
    <div className='col-xl-5 offset-xl-1 col-lg-6 col-md-12 col-12'>
      <div className='login_warp'>
        <div className='login_wrapper'>
          <div className='login_form'>
            <form method='#' action='index.html'>
              <div className='row'>
                <div className='col-12' style={{ textAlign: 'center', marginTop: '10%' }}>
                  <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

  )
}

export default LoginForm
