import React, { useState } from 'react'
import validator from 'validator'

export const IntegerInput = ({ initialValue, onChange }) => {
  const [value, setValue] = useState(initialValue)

  function handleChange (event) {
    const newValue = event.target.value
    if (newValue === '' || validator.isInt(newValue)) {
      setValue(newValue)
      onChange(newValue)
    }
  }

  return <textarea value={value} onChange={handleChange} />
}
