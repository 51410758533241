import { handleToggleDarkTheme } from '../../store/theme'
import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import { useDispatch, useSelector } from 'react-redux'
import './../../assets/css/style.css'
import './../../assets/css/responsive.css'
import './../../assets/css/default.css'

export const ThemeSetting = () => {
  const dispatch = useDispatch()
  const darkTheme = useSelector(state => state.darkTheme)

  const handleThemeChange = () => {
    dispatch(handleToggleDarkTheme(darkTheme))
  }

  return (
    <div className='row mt_20'>
      <div className='col-md-7 col-6'>
        <div className='switcher'>
          <label htmlFor='dark'>Dark Theme</label>
        </div>
      </div>
      <div className='col-md-3 col-6 night_btnc'>
        <label className='switch'>
          <input type='checkbox' />
          <span className='slider round' onClick={handleThemeChange} />
        </label>
      </div>
    </div>
  )
}
